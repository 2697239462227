import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Suspense as _Suspense } from "vue"

const _hoisted_1 = {
  class: "embed-carousel",
  ref: "embedCarouselRef"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_InstagramEmbedded = _resolveComponent("InstagramEmbedded")!
  const _component_VimeoEmbedded = _resolveComponent("VimeoEmbedded")!
  const _component_TikTokEmbedded = _resolveComponent("TikTokEmbedded")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_a_typography_title, {
          key: 0,
          level: 5,
          class: "m-0 mb-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Suspense, { onResolve: _ctx.handleInjectEmbedScripts }, {
      fallback: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          active: "",
          class: "embed-carousel__loading",
          title: { width: '100%' },
          paragraph: { rows: 2 }
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_carousel, {
          arrows: _ctx.showArrows,
          "dots-class": "slick-dots",
          afterChange: _ctx.handleAfterChange
        }, {
          prevArrow: _withCtx(() => [
            _createVNode(_component_LeftOutlined, { class: "embed-carousel__slick" })
          ]),
          nextArrow: _withCtx(() => [
            _createVNode(_component_RightOutlined, { class: "embed-carousel__slick" })
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.embedUrls, (url) => {
              return (_openBlock(), _createElementBlock("div", { key: url }, [
                (_ctx.isValidInstagramUrl(url))
                  ? (_openBlock(), _createBlock(_component_InstagramEmbedded, {
                      key: 0,
                      url: url
                    }, null, 8, ["url"]))
                  : (_ctx.isValidVimeoUrl(url))
                    ? (_openBlock(), _createBlock(_component_VimeoEmbedded, {
                        key: 1,
                        "embed-src": url
                      }, null, 8, ["embed-src"]))
                    : (_ctx.isValidTiktokUrl(url))
                      ? (_openBlock(), _createBlock(_component_TikTokEmbedded, {
                          key: 2,
                          url: url
                        }, null, 8, ["url"]))
                      : (_openBlock(), _createElementBlock("iframe", {
                          key: 3,
                          src: url,
                          frameborder: "0",
                          allowfullscreen: ""
                        }, null, 8, _hoisted_2))
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["arrows", "afterChange"])
      ]),
      _: 1
    }, 8, ["onResolve"]))
  ], 512))
}