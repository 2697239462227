import axios from "axios";

export const createTikTokEmbeddedScript = () => {
  const script = window.document.createElement("script");
  script.setAttribute("src", "https://www.tiktok.com/embed.js");
  script.async = true;
  return script;
};

export const getTikTokEmbeddedCode = async (tikTokVideoUrl: string) => {
  if (!tikTokVideoUrl) {
    return "";
  }

  /**
   * TODO: this will be moved to internal endpoint
   */
  const response = await axios.get(
    `https://www.tiktok.com/oembed?url=${tikTokVideoUrl}`
  );

  return response?.data?.html;
};
