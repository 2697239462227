
import { defineComponent, PropType, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import InstagramEmbedded from "@/shared/components/VideoEmbed/InstagramEmbedded.vue";
import TikTokEmbedded from "@/shared/components/VideoEmbed/TikTokEmbedded.vue";
import VimeoEmbedded from "@/shared/components/VimeoEmbedded.vue";
import { createTikTokEmbeddedScript } from "@/shared/utils/tiktok";
import { createVimeoEmbeddedScript } from "@/shared/utils/vimeo";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";
import { isValidInstagramUrl } from "@/shared/components/VideoEmbed/InstagramEmbedded.vue";
import { isValidTiktokUrl } from "@/shared/components/VideoEmbed/TikTokEmbedded.vue";
import { isValidVimeoUrl } from "@/shared/components/VimeoEmbedded.vue";

export type VideoEmbedType = "instagram" | "tiktok" | "vimeo" | "iframe";

/**
 * This component handles carousel of different embed types
 */
export default defineComponent({
  props: {
    label: String,
    embedUrls: Array as PropType<string[]>,
    hasArrows: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const embedCarouselRef = ref();

    /**
     * Programmatically inject TikTok and Vimeo Embedded code in to the component
     * after all embedded html codes are ready
     */
    const handleInjectEmbedScripts = () => {
      try {
        // prepend to the component only, so it will be destroyed on unmount
        embedCarouselRef.value.prepend(createTikTokEmbeddedScript());
        embedCarouselRef.value.prepend(createVimeoEmbeddedScript());
      } catch (error) {
        console.log("Something went wrong when trying to inject embed scripts");
      }
    };

    /**
     * Get the slick-slide element in embed-carousel > ant-carousel
     * @param index index of the slide in carousel
     */
    const getSlideElement = (index: number) => {
      return document.querySelector(
        `.embed-carousel > .ant-carousel .slick-track .slick-slide[data-index="${index}"]`
      );
    };

    /**
     * Reloads the embed code inside the slide `.tiktok-embed__container` or `.instagram-embed__container`
     * To pause the videos
     * @param index index of the slide in carousel
     */
    const reloadEmbedCode = async (index: number) => {
      const slideElement = getSlideElement(index);
      const embedContainer = slideElement?.querySelector(
        ".tiktok-embed__container, .instagram-embed__container"
      );
      try {
        // Re-assign innerHTML to the same code, just empty it first to pause the video
        const html = embedContainer!.innerHTML;
        embedContainer!.innerHTML = "";
        embedContainer!.innerHTML = html;

        // Remove the embed script, then re-add it to trigger a re-initialization of iframe
        embedCarouselRef.value.querySelector("script").remove();
        embedCarouselRef.value.prepend(createTikTokEmbeddedScript());
      } catch (e) {
        console.error(e);
      }
    };

    /** See: https://developer.vimeo.com/player/sdk/basics */
    const pauseVimeoEmbed = (index: number) => {
      try {
        const slideElement = getSlideElement(index);
        const vimeoEmbed = slideElement?.querySelector("iframe.vimeo-embed");
        const _window = window as any;
        const player = new _window.Vimeo.Player(vimeoEmbed);
        player.pause();
      } catch (e) {
        console.error(e);
      }
    };

    const handleAfterChange = async (current: number) => {
      // FIXME: ant-design from value currently always returns undefined (might be a bug)
      // Current workaround: pause videos on before and after of the current slide
      const container = document.querySelector(
        ".embed-carousel > .ant-carousel .slick-track"
      );

      if (container) {
        const total = props.embedUrls?.length ?? 0;

        // Set beforeIndex to wrap around last element
        const beforeIndex = current <= 0 ? total - 1 : current - 1;
        // Set afterIndex to wrap around first element
        const afterIndex = (current + 1) % total;
        if (beforeIndex >= 0) {
          reloadEmbedCode(beforeIndex);
          pauseVimeoEmbed(beforeIndex);
        }
        if (afterIndex < container.children.length) {
          reloadEmbedCode(afterIndex);
          pauseVimeoEmbed(afterIndex);
        }
      }
    };

    return {
      t,
      handleInjectEmbedScripts,
      embedCarouselRef,
      handleAfterChange,
      showArrows: computed(
        () => (props.embedUrls?.length ?? 0) > 1 && props.hasArrows
      ),
      isValidInstagramUrl,
      isValidVimeoUrl,
      isValidTiktokUrl,
    };
  },
  components: {
    TikTokEmbedded,
    InstagramEmbedded,
    VimeoEmbedded,
    LeftOutlined,
    RightOutlined,
  },
});
