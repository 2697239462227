
import { checkURLHost } from "@/shared/utils/browser";
import { defineComponent } from "vue";

export const isValidInstagramUrl = (url: string) =>
  checkURLHost(url, "instagram.com");

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const instagramEmbedUrl = props.url.endsWith("/")
      ? props.url + "embed"
      : props.url + "/embed";

    return {
      isValidInstagramUrl,
      instagramEmbedUrl,
    };
  },
});
