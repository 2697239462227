import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "instagram-embed__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: _ctx.instagramEmbedUrl,
      class: "instagram-embed",
      frameborder: "0",
      scrolling: "no",
      allowtransparency: "true"
    }, "\n    ", 8, _hoisted_2)
  ]))
}