
import { defineComponent } from "vue";
import { getTikTokEmbeddedCode } from "@/shared/utils/tiktok";
import { checkURLHost } from "@/shared/utils/browser";

export const isValidTiktokUrl = (url: string) =>
  checkURLHost(url, "tiktok.com");

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  /**
   * Only render component if getTikTokEmbeddedCode is ready
   * Add <Suspense></Suspense> to the parent component to be able to render this component
   */
  async setup(props) {
    const embeddedHtml = await getTikTokEmbeddedCode(props.url);

    return { embeddedHtml };
  },
});
